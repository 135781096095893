<template>
    <div class="back">
        <v-container bg fill-height grid-list-md text-xs-center>
            <v-layout row wrap align-center justify-center>
                <v-col md="7" sm="12">
                </v-col>
                <v-col md="5" sm="12">
                    <v-row>
                        <slot />
                    </v-row>
                </v-col>
            </v-layout>
          <span class="version">version 2.20.8</span>
        </v-container>
    </div>
</template>

<script>
  export default {
    name: "Login"
  }
</script>

<style scoped>
    .back {
        background-image: url('../assets/tlo_logowanie.jpg');
        background-size: cover;
        height: 100%;
    }
    .version {
      position: absolute;
      bottom: 0;
      right: 0;
    }
</style>
